
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import { PublicUser, DatingService, GetProspectsResponse, GetPastSwipesResponse, SwipeRequest, UserService } from '@bostonventurestudio/lola-api';
    import { mixins } from 'vue-class-component';
    import VueConstants from '@/components/VueConstants';
    import { PropOptions } from 'vue';
    import { SharedConstants } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserSwipes',

        components: {},

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<PublicUser>,
        },

        data() {
            return {
                swipes: [] as {
                    reviewable: boolean;
                    userId?: number;
                    affinity?: boolean;
                    profileMediaUrl?: string;
                }[],
                swipeFields: [
                    { key: 'profileMediaUrl', tdClass: 'align-middle' },
                    { key: 'user_id', tdClass: 'align-middle' },
                    { key: 'swipe_status', tdClass: 'align-middle' },
                    { key: 'swipeType', tdClass: 'align-middle' },
                ],
                allItems: [] as any[],
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: PublicUser, oldVal: PublicUser) {
                    if (newVal && newVal !== oldVal) {
                        this.fetchSwipes();
                    }
                },
            },
        },

        computed: {},

        methods: {
            async fetchSwipes() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'fetchSwipes');
                    return;
                }

                try {
                    const reviewableSwipesRet = (await ApiUtils.apiWrapper(DatingService.getProspectsForReview, {
                        userId: this.user.userId,
                    })) as GetProspectsResponse;

                    const allSwipesRet = (await ApiUtils.apiWrapper(DatingService.getPastSwipes, {
                        userId: this.user.userId,
                        page: 1,
                        pageSize: SharedConstants.DATING_SWIPES_PAGE_SIZE,
                    } as SwipeRequest)) as GetPastSwipesResponse;

                    const combinedSwipes = this.combineSwipes(allSwipesRet, reviewableSwipesRet);

                    this.swipes = combinedSwipes || [];
                    this.$emit('swipe-count', this.swipes.length);

                    this.buildItemList();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            combineSwipes(allSwipesRet: GetPastSwipesResponse, reviewableSwipesRet: GetProspectsResponse) {
                // array of booleans
                const affinities: boolean[] = allSwipesRet.affinities || [];

                const combinedSwipes = [] as {
                    reviewable: boolean;
                    userId?: number;
                    affinity?: boolean;
                    profileMediaUrl?: string;
                }[];

                // loop through all swipes and add the reviewable flag
                if (allSwipesRet.prospects) {
                    allSwipesRet.prospects.forEach((prospect, index) => {
                        const reviewable = reviewableSwipesRet.prospects?.some((reviewableProspect) => {
                            return reviewableProspect.userId === prospect.userId;
                        });

                        combinedSwipes.push({
                            reviewable: reviewable || false,
                            userId: prospect.userId,
                            affinity: affinities[index],
                            profileMediaUrl: prospect.profileMediaUrl,
                        });
                    });
                }

                return combinedSwipes;
            },

            async buildItemList() {
                if (this.swipes.length === 0) {
                    this.allItems = [];
                    return;
                }

                const newItems = this.swipes.map(async (item) => {
                    const user = (await ApiUtils.apiWrapper(UserService.getPublicUserById, item.userId)) as PublicUser;
                    const usersName = user.firstName + ' ' + (user.lastName || '');

                    return {
                        profileMediaUrl: item.profileMediaUrl,
                        user_id: item.userId,
                        name: usersName,
                        swipe_status: item.reviewable ? 'Reviewable' : 'Past Swipe',
                        affinity: item.affinity,
                        swipeType: item.affinity ? 'Liked' : 'Disliked',
                    };
                });

                this.allItems = await Promise.all(newItems);
            },

            async userClicked(item: any) {
                if (item && item.user_id) {
                    const url = this.$router.resolve({
                        name: constants.ROUTE_USER_HOME,
                        params: { userId: item.user_id },
                    }).href;
                    window.open(url, '_blank');
                }
            },
        },
    });
