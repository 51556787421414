
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import { Utils } from '@/tsfiles/utils';
    import { DateTime as LuxonDateTime } from 'luxon';
    import Avatar from '@/components/Avatar.vue';
    import { PublicUser, Post, SharedConstants, DatePlan } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'Chat',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            item: {
                type: Object,
                required: true,
            } as PropOptions<Post>,

            author: {
                type: Object,
                required: true,
            } as PropOptions<PublicUser>,

            showReceipt: {
                type: Boolean,
                required: false,
                default: false,
            },
            latestSuggestedPlan: {
                type: Object,
                required: false,
            } as PropOptions<DatePlan>,
        },

        data() {
            return {
                json: undefined as any | undefined,
                showMatchCalendar: false,
            };
        },

        watch: {
            item: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && newVal.message) {
                        this.json = JSON.parse(this.item.message as string);
                    } else {
                        this.json = undefined as any | undefined;
                    }
                },
            },
        },

        computed: {
            isMe(): boolean {
                if (!this.item || !this.item.authorUserId) {
                    logInvalidParams(this.$options.name, 'isMe');
                    return false;
                }

                return this.$store.getters.isAuthedUserId(this.item.authorUserId);
            },

            chatTime(): string {
                if (!this.item.updated) {
                    return '';
                }

                return LuxonDateTime.fromISO(this.item.updated).toLocaleString(LuxonDateTime.TIME_SIMPLE);
            },

            getChatMessage(): string {
                if (!this.json) {
                    return '';
                }

                if (this.json.cv) {
                    if (this.isMe && this.$store.getters.isAuthedUserId(this.author.userId)) {
                        return 'Contact info shared.';
                    }

                    return this.author.firstName + ' shared their contact info.';
                }
                if (this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_ASKED_OUT) {
                    if (this.isMe && this.$store.getters.isAuthedUserId(this.author.userId)) {
                        return 'You asked out ' + this.author.firstName + ' on a date. message: ' + this.json.message;
                    }

                    return this.author.firstName + ' asked you out on a date. message: ' + this.json.message;
                } else if (this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_ACCEPTED) {
                    if (this.isMe && this.$store.getters.isAuthedUserId(this.author.userId)) {
                        return 'You accepted date with ' + this.author.firstName;
                    }

                    return this.author.firstName + ' accepted your date request';
                } else if (this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_SHARED_AVAILABILITY) {
                    var availabilityStr: string[] = [];
                    for (const day of this.json.availableDays) {
                        const lDate = LuxonDateTime.fromISO(day);
                        availabilityStr.push(lDate.toFormat('MMMM d'));
                    }

                    if (this.isMe && this.$store.getters.isAuthedUserId(this.author.userId)) {
                        return 'You shared availability: ' + availabilityStr.join(', ');
                    }

                    return this.author.firstName + ' shared their availability: ' + availabilityStr.join(', ');
                } else if (this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_RESCHEDULED) {
                    if (this.isMe && this.$store.getters.isAuthedUserId(this.author.userId)) {
                        return 'You rescheduled the date.';
                    }

                    return this.author.firstName + ' rescheduled the date.';
                } else if (this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_DATE_PLAN_SUGGESTED) {
                    var plan = this.json.datePlan;
                    if (this.isMe && this.$store.getters.isAuthedUserId(this.author.userId)) {
                        return 'You suggested a date plan.';
                    }

                    return this.author.firstName + ' suggested a date plan.';
                } else if (this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_DATE_PLAN_APPROVED) {
                    if (this.isMe && this.$store.getters.isAuthedUserId(this.author.userId)) {
                        return 'You accepted the date plan.';
                    }

                    return this.author.firstName + ' accepted the date plan.';
                }

                // Normal chat messages (e.g., {"message": "this is a message"})
                let ret = this.json.message;
                if (this.json.date && this.json.message.includes('{{DATE_EXPAND}}')) {
                    const localDate = LuxonDateTime.fromISO(this.json.date).toLocaleString({
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                        timeZoneName: 'short',
                    });

                    ret = ret.replace('{{DATE_EXPAND}}', localDate);
                }

                return ret;
            },

            isDatePlan(): boolean {
                if (!this.json) {
                    return false;
                }
                return (
                    this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_DATE_PLAN_SUGGESTED ||
                    this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_DATE_PLAN_APPROVED
                );
            },

            getDatePlanActivity(): string {
                if (!this.json || !this.isDatePlan) {
                    return '';
                }

                return this.capitalizeFirstLetter(this.json.datePlan.activity);
            },
            getDatePlanTime(): string {
                if (!this.json || !this.isDatePlan) {
                    return '';
                }

                return this.convertTimeTo12HourFormat(this.json.datePlan.time);
            },
            getDatePlanPlaceName(): string {
                if (!this.json || !this.isDatePlan) {
                    return '';
                }

                return this.json.datePlan.placeName;
            },
            showButtons(): boolean {
                return (
                    this.json.messageType == SharedConstants.CHAT_POST_MESSAGE_TYPE_DATE_PLAN_SUGGESTED &&
                    this.latestSuggestedPlan?.planId == this.json.datePlan?.planId &&
                    this.latestSuggestedPlan.status == SharedConstants.DATING_DATE_PLAN_STATUS_SUGGESTED
                );
            },
            serverMessage(): boolean {
                return this.json && this.json.serverGenerated;
            },
        },

        methods: {
            getServerMessage(): string {
                if (!this.json) {
                    return '';
                }

                return this.json.message;
            },
            convertTimeTo12HourFormat(time24: string): string {
                if (!time24) return ''; // Return empty string if no time string is provided
                // Split the time string into hours and minutes
                const [hours, minutes] = time24?.split(':').map(Number);
                const suffix = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM suffix
                const hours12 = hours % 12 || 12; // Convert hours from 24-hour to 12-hour format

                // Return the formatted time string
                return `${hours12}:${minutes.toString().padStart(2, '0')} ${suffix}`;
            },
            capitalizeFirstLetter(string: string): string {
                if (!string) return '';
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            acceptDatePlan() {
                this.$emit('accept-date-plan', this.item);
            },
            editDatePlan() {
                this.$emit('edit-date-plan', this.item);
            },
        },
    });
