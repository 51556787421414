<!--
  -- Copyright 2023-2024 Lola - All rights reserved.
  -- File: CompletedPostDateSurvey.vue
  -- Project: Lola
-->
<template>
    <div>
        <p>Date Confirmed: {{ survey.dateConfirmed ? 'Yes' : 'No' }}</p>
        <p v-if="survey.dateConfirmResponse">Date Confirm Response: {{ survey.dateConfirmResponse }}</p>
        <p v-if="survey.noDateReason">No Date Reason: {{ survey.noDateReason }}</p>
        <p v-if="survey.noDateButInterestedReason">No Date But Interested Reason: {{ survey.noDateButInterestedReason }}</p>
        <p v-if="survey.noDateOther">No Date Other: {{ survey.noDateOther }}</p>
        <p v-if="survey.rating">Rating: {{ survey.rating }}</p>
        <p v-if="survey.badDateReason">Bad Date Reason: {{ survey.badDateReason }}</p>
        <p v-if="survey.badDateUnsafe">Bad Date Unsafe: {{ survey.badDateUnsafe }}</p>
        <p v-if="survey.badDateOther">Bad Date Other: {{ survey.badDateOther }}</p>
        <p v-if="survey.likeTags && survey.likeTags.length > 0">Likes: {{ survey.likeTags.join(', ') }}</p>
        <p v-if="survey.dislikeTags && survey.dislikeTags.length > 0">Dislikes: {{ survey.dislikeTags.join(', ') }}</p>
        <p>Date Again: {{ survey.dateAgain ? 'Yes' : 'No' }}</p>
        <p>Partner Name: {{ receivedSurvey ? survey.userName : survey.partnerName }}</p>
        <p>Date: {{ survey.date }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            survey: {
                type: Object,
                required: true,
            },
            receivedSurvey: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
