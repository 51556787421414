
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { DateTime as LuxonDateTime } from 'luxon';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import VueConstants from '@/components/VueConstants';
    import DatingMatchRow from '@/components/dating/DatingMatchRow.vue';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import PastDateRow from '@/components/dating/PastDateRow.vue';
    import PostDateSurveyCompleted from '@/components/dating/CompletedPostDateSurvey.vue';

    import { SharedConstants, DatingService, Match, CompletedPostDateSurvey } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'CompletedPostDateSurveys',

        components: {
            'past-date': PastDateRow,
            'completed-post-date-survey': PostDateSurveyCompleted,
        },

        props: {},

        data() {
            return {
                tabs: [SharedConstants.COMPLETED_POST_DATE_SURVEY_GIVEN, SharedConstants.COMPLETED_POST_DATE_SURVEY_RECEIVED],
                activeTab: 0,
                completedSurveys: [] as CompletedPostDateSurvey[],
                loading: false,
                pagination: {
                    [SharedConstants.COMPLETED_POST_DATE_SURVEY_GIVEN]: {
                        page: 1,
                        pageSize: SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE,
                        totalRecords: 0,
                        currentPage: 1,
                    },
                    [SharedConstants.COMPLETED_POST_DATE_SURVEY_RECEIVED]: {
                        page: 1,
                        pageSize: SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE,
                        totalRecords: 0,
                        currentPage: 1,
                    },
                },
                expandedRow: -1,
            };
        },
        mounted() {
            this.fetchCompletedSurveys();
        },

        computed: {
            hasAccess(): boolean {
                return (
                    this.$store.getters.isSignedIn &&
                    (!this.gettingRecordForAnotherUser || (this.gettingRecordForAnotherUser && RoleUtils.CanSupportUsers()))
                );
            },
            gettingRecordForAnotherUser(): boolean {
                return this.$route.params.userId != this.$store.getters.getAuthedUserId;
            },
            userId(): number {
                return parseInt(this.$route.params.userId == this.$store.getters.getAuthedUserId ? '' : this.$route.params.userId);
            },
            isReceivedTab(): boolean {
                console.log('isReceivedTab:', this.tabs[this.activeTab]);
                return this.tabs[this.activeTab] === 'received';
            },
        },

        methods: {
            async fetchCompletedSurveys(page: number = 1) {
                this.loading = true;
                const state = this.tabs[this.activeTab] as 'given' | 'received';
                var pagination = this.pagination[state];
                try {
                    let query = {};
                    if (this.userId > 0)
                        query = {
                            userId: this.userId,
                            state: this.activeTab === 0 ? 'given' : 'received',
                            page: pagination.page || 1, // Use the page from filters, default to 1 if not provided
                            pageSize: pagination.pageSize || SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE, // Use pageSize from filters or default constant
                        };
                    else
                        query = {
                            state: this.activeTab === 0 ? 'given' : 'received',
                            page: pagination.page || 1, // Use the page from filters, default to 1 if not provided
                            pageSize: pagination.pageSize || SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE, // Use pageSize from filters or default constant
                        };
                    const ret = await ApiUtils.apiWrapper(DatingService.getCompletedPostDateSurveys, query);
                    if (ret && ret.postDateSurveys && ret.postDateSurveys.length > 0) {
                        this.completedSurveys = ret.postDateSurveys;
                        console.log('total records:', ret.totalRecords);
                        this.pagination[state].page = ret.page ?? 1;
                        this.pagination[state].pageSize = ret.pageSize ?? SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE;
                        this.pagination[state].totalRecords = ret.totalRecords ?? 0;
                    }
                    console.log('Completed Surveys:', this.completedSurveys);
                    this.loading = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            rowSelected(idx: number) {
                if (idx < 0) {
                    logInvalidParams(this.$options.name, 'rowSelected');
                    return;
                }

                const item = this.completedSurveys[idx];
                if (!item || !item.matchId || !item.partnerUserId || item.partnerUserId <= 0) {
                    logInvalidParams(this.$options.name, 'rowSelected - invalid partnerUserId');
                    return;
                }

                if (this.expandedRow === idx) {
                    this.expandedRow = -1;
                } else {
                    this.expandedRow = idx;
                }
            },
            tabTitle(tab: string) {
                switch (tab) {
                    case SharedConstants.COMPLETED_POST_DATE_SURVEY_GIVEN:
                        return 'GIVEN';
                    case SharedConstants.COMPLETED_POST_DATE_SURVEY_RECEIVED:
                        return 'RECEIVED';
                    default:
                        return 'Unknown';
                }
            },
            showPagination() {
                return this.completedSurveys && this.completedSurveys?.length;
            },
            /**
             * Returns a string representing the pagination information.
             * @returns {string} The pagination information.
             */
            getPaginationInfo(): string {
                const pagination = this.getPagination();
                var showing = (pagination.page - 1) * pagination.pageSize + 1;
                return (
                    'Showing ' +
                    showing +
                    ' - ' +
                    Math.min(pagination.page * pagination.pageSize, pagination.totalRecords) +
                    ' of ' +
                    pagination.totalRecords +
                    ' Surveys.'
                );
            },
            getPagination() {
                const state = this.tabs[this.activeTab] as 'given' | 'received';
                return this.pagination[state];
            },
            async handlePageChange(page: number) {
                // Update the page number and perform the search again
                const state = this.tabs[this.activeTab] as 'given' | 'received';
                this.pagination[state].page = page;
                await this.fetchCompletedSurveys();
            },
        },
    });
