<template>
    <div>
        <span class="card-header-text">Places By Neighborhood</span>

        <!-- "Create New Place" Button -->
        <div class="mb-3 text-right">
            <b-button
                variant="success"
                @click="handleCreatePlace">
                Create New Place
            </b-button>
        </div>
        <!-- Filters Section -->
        <div class="filters">
            <label
                for="marketNeighborhoodId"
                class="gray-text"
                >Select Market Neighborhood:</label
            >
            <b-form-select
                id="marketNeighborhoodId"
                v-model="marketNeighborhoodId"
                :options="neighborhoodOptions"
                @change="fetchPlaces"
                class="custom-dropdown">
            </b-form-select>
        </div>

        <!-- Filters for Place Status -->
        <div class="d-flex flex-row align-items-center">
            <span class="gray-text">Filter by Status:</span>
            <div class="mx-auto" />
            <b-link @click="setItemsFilterType('')">All</b-link>
            <span class="px-1">|</span>
            <b-link @click="setItemsFilterType('active')">Active</b-link>
            <span class="px-1">|</span>
            <b-link @click="setItemsFilterType('deactivated')">Deactivated</b-link>
        </div>

        <!-- Table Section -->
        <b-table
            bordered
            outlined
            small
            hover
            head-variant="light"
            table-variant="light"
            thead-tr-class="text-center"
            :fields="fields"
            :items="filteredPlaces"
            @row-clicked="handleEditPlace">
            <template #cell(longitude)="data">
                <div class="text-nowrap">{{ data.item.location ? data.item.location.longitude : 'N/A' }}</div>
            </template>

            <template #cell(latitude)="data">
                <div class="text-nowrap">{{ data.item.location ? data.item.location.latitude : 'N/A' }}</div>
            </template>

            <template #cell(address)="data">
                <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(active)="data">
                <div class="text-nowrap">
                    <span v-if="data.value === 1 || data.value === true">Yes</span>
                    <span v-else>No</span>
                </div>
            </template>
            <template #cell(recommendedTime)="data">
                <div class="text-nowrap">{{ data.value || 'N/A' }}</div>
            </template>

            <template #cell(action)="data">
                <div class="text-nowrap">
                    <b-button
                        @click.stop="handleEditPlace(data.item)"
                        size="sm"
                        variant="primary">
                        Edit
                    </b-button>
                </div>
            </template>
        </b-table>

        <b-modal
            v-model="isModalVisible"
            hide-footer
            @hidden="onModalClose">
            <div>
                <b-form @submit="submitForm">
                    <b-form-group label="Place Name">
                        <b-form-input
                            v-model="placeForm.name"
                            required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Phone Number">
                        <b-form-input v-model="placeForm.phoneNumber"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Address">
                        <b-form-input v-model="placeForm.address"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Latitude">
                        <b-form-input v-model="placeForm.location.latitude"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Longitude">
                        <b-form-input v-model="placeForm.location.longitude"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Market Neighborhood">
                        <b-form-select
                            v-model="placeForm.marketNeighborhoodId"
                            :options="neighborhoodOptions"
                            required>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label="Pricing Level">
                        <b-form-input v-model="placeForm.pricingLevel"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Photo URL">
                        <b-form-input v-model="placeForm.photoUrl"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Google Reference">
                        <b-form-input v-model="placeForm.googleReference"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Website">
                        <b-form-input v-model="placeForm.website"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Reservation Link">
                        <b-form-input v-model="placeForm.reservationLink"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Rating">
                        <b-form-input
                            v-model="placeForm.rating"
                            type="number"
                            step="0.1"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Source">
                        <b-form-select
                            v-model="placeForm.source"
                            :options="sourceOptions"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Recommendation">
                        <b-form-input v-model="placeForm.recommendation"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Recommended Activity">
                        <b-form-input v-model="placeForm.recommendedActivity"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Recommended Time">
                        <b-form-input v-model="placeForm.recommendedTime"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Active Status">
                        <b-form-checkbox v-model="placeForm.active">Active</b-form-checkbox>
                    </b-form-group>
                    <b-button
                        type="submit"
                        variant="primary">
                        Save
                    </b-button>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { AdminService, SharedConstants, SavePlaceRequest } from '@bostonventurestudio/lola-api';

    export default {
        name: 'GetPlacesByNeighborhood',
        data() {
            return {
                places: [],
                filteredPlaces: [],
                neighborhoodOptions: [],
                neighborhoodOptionsForFetch: [],
                marketNeighborhoodId: 0,
                sourceOptions: [
                    { value: 'Google', text: SharedConstants.DATING_PLACE_SOURCE_GOOGLE },
                    { value: 'Lola', text: SharedConstants.DATING_PLACE_SOURCE_LOLA },
                ],
                filterItemsType: '',
                fields: [
                    { key: 'name', label: 'Place Name' },
                    { key: 'longitude', label: 'Longitude' },
                    { key: 'latitude', label: 'Latitude' },
                    { key: 'address', label: 'Address' },
                    { key: 'active', label: 'Active' },
                    { key: 'recommendedTime', label: 'Recommended Time' },
                    { key: 'action', label: 'Actions' },
                ],
                placeForm: {
                    id: null,
                    name: '',
                    phoneNumber: '',
                    address: '',
                    location: { latitude: '', longitude: '' },
                    marketNeighborhoodId: null,
                    pricingLevel: '',
                    photoUrl: '',
                    googleReference: '',
                    website: '',
                    reservationLink: '',
                    rating: null,
                    source: '',
                    recommendation: '',
                    recommendedActivity: '',
                    recommendedTime: '',
                    active: true,
                },
                isModalVisible: false,
            };
        },
        methods: {
            async fetchNeighborhoods() {
                try {
                    const response = await fetch('/v1/dating/getActiveNeighborhoods');
                    if (!response.ok) throw new Error('Failed to fetch neighborhoods.');
                    const data = await response.json();

                    // For fetching places dropdown, include the "All Neighborhoods" option
                    this.neighborhoodOptionsForFetch = [
                        { value: 0, text: 'All Neighborhoods' },
                        ...data.neighborhoods.map((neighborhood) => ({
                            value: neighborhood.id,
                            text: neighborhood.name,
                        })),
                    ];

                    // For the place form dropdown, do not include "All Neighborhoods"
                    this.neighborhoodOptions = data.neighborhoods.map((neighborhood) => ({
                        value: neighborhood.id,
                        text: neighborhood.name,
                    }));
                } catch (error) {
                    console.error('Error fetching neighborhoods:', error);
                }
            },

            async fetchPlaces() {
                const neighborhoodId = this.marketNeighborhoodId === 0 ? 0 : this.marketNeighborhoodId;

                try {
                    const response = await fetch(`/v1/dating/getPlacesByNeighborhood?marketNeighborhoodId=${neighborhoodId}`);
                    if (!response.ok) throw new Error('Failed to fetch places.');
                    const data = await response.json();
                    this.places = data.places || [];
                    this.filterPlaces();
                } catch (error) {
                    console.error('Error fetching places:', error);
                    this.places = [];
                }
            },
            filterPlaces() {
                if (this.filterItemsType === 'active') {
                    this.filteredPlaces = this.places.filter((place) => place.active);
                } else if (this.filterItemsType === 'deactivated') {
                    this.filteredPlaces = this.places.filter((place) => !place.active);
                } else {
                    this.filteredPlaces = this.places;
                }
            },
            setItemsFilterType(type) {
                this.filterItemsType = type;
                this.filterPlaces();
            },
            handleCreatePlace() {
                this.placeForm = {
                    id: null,
                    name: '',
                    phoneNumber: '',
                    address: '',
                    location: { latitude: '', longitude: '' },
                    marketNeighborhoodId: null,
                    pricingLevel: '',
                    photoUrl: '',
                    googleReference: '',
                    website: '',
                    reservationLink: '',
                    rating: null,
                    source: '',
                    recommendation: '',
                    recommendedActivity: '',
                    recommendedTime: '',
                    active: true,
                };
                this.isModalVisible = true;
            },

            handleEditPlace(place) {
                if (!place || !place.id) {
                    console.error('Place object is missing or ID is undefined');
                    return;
                }
                this.placeForm = { ...place };
                this.isModalVisible = true;
            },
            async submitForm(event) {
                event.preventDefault();
                try {
                    const payload = { ...this.placeForm };
                    if (!payload.id) delete payload.id;
                    payload.location.latitude = payload.location.latitude || '';
                    payload.location.longitude = payload.location.longitude || '';
                    payload.rating = payload.rating || null;

                    const response = await ApiUtils.apiWrapper(AdminService.savePlace, payload);

                    // Explicitly close the modal and reset form state
                    this.$nextTick(() => {
                        this.isModalVisible = false;
                        this.placeForm = {
                            id: null,
                            name: '',
                            phoneNumber: '',
                            address: '',
                            location: { latitude: '', longitude: '' },
                            marketNeighborhoodId: null,
                            pricingLevel: '',
                            photoUrl: '',
                            googleReference: '',
                            website: '',
                            reservationLink: '',
                            rating: null,
                            source: '',
                            recommendation: '',
                            recommendedActivity: '',
                            recommendedTime: '',
                            active: true,
                        };
                    });

                    this.fetchPlaces();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            onModalClose() {
                this.isModalVisible = false;
            },
        },
        created() {
            this.fetchNeighborhoods();
            this.fetchPlaces(0);
        },
    };
</script>
<style>
    .custom-dropdown {
        background-color: #f8f9fa; /* Same background color as the table */
        border: 1px solid #dee2e6; /* Same border as the table */
        border-radius: 4px; /* Same border radius as the table */
        padding: 0.375rem 0.75rem; /* Adjust padding for the dropdown */
        font-size: 1rem; /* Ensure the font size matches */
    }

    .custom-dropdown:focus {
        border-color: #007bff; /* Highlight on focus (optional) */
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25); /* Focus shadow */
    }
    .gray-text {
        color: #6c757d; /* Gray color */
    }
</style>
