
    import { Vue } from 'vue-property-decorator';
    import * as constants from '@/tsfiles/constants';
    import { logAccessDenied, logInvalidParams } from '@/tsfiles/errorlog';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { Neighborhood, AdminService, Location } from '@bostonventurestudio/lola-api';
    import { PropType } from 'vue';

    export default Vue.extend({
        name: 'CreateUpdateNeighborhoodModal',

        components: {},

        props: {
            isCreateMode: {
                type: Boolean,
                default: true,
            },
            obj: {
                type: Object as PropType<Neighborhood>,
                required: false,
            },
        },
        watch: {
            isCreateMode: {
                immediate: true,
                handler() {
                    if (this.isCreateMode) {
                        this.resetForm();
                    } else {
                        this.initData();
                    }
                },
            },
        },

        data() {
            return {
                neighborhood: {} as Neighborhood,
                longitude: '',
                latitude: '',
                markets: [] as string[],
                selectedOption: 'Boston',
                isActive: false,
            };
        },

        mounted() {
            // Just double-check that the current user has access
            if (!RoleUtils.CanSupportUsers()) {
                logAccessDenied(this.$options.name, 'mounted');
                this.$router.replace({ name: constants.ROUTE_MARKETING });
            }
            this.markets = ['Boston'];
        },

        computed: {
            //
            // Should the submit button be disabled?  It should if all required fields are
            // not valid.
            //
            disableSubmit(): boolean {
                return (
                    !this.neighborhood.name ||
                    !this.selectedOption ||
                    !this.neighborhood.location ||
                    !Utils.ValidateLocationLatitude(this.latitude) ||
                    !Utils.ValidateLocationLongitude(this.longitude)
                );
            },

            latitudeValid(): boolean {
                return Utils.ValidateLocationLatitude(this.latitude);
            },

            longitudeValid(): boolean {
                return Utils.ValidateLocationLongitude(this.longitude);
            },
        },

        methods: {
            async saveNeighborhood() {
                if (this.disableSubmit || !this.neighborhood) {
                    logInvalidParams(this.$options.name, 'submit');
                    return;
                }

                try {
                    this.neighborhood.location = {
                        latitude: this.latitude,
                        longitude: this.longitude,
                    } as Location;
                    this.neighborhood.active = this.isActive;
                    this.neighborhood.marketName = this.selectedOption;
                    const ret = await ApiUtils.apiWrapper(AdminService.saveNeighborhood, this.neighborhood);
                    this.$emit('closed');
                    this.$emit('saved');
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },
            resetForm() {
                this.neighborhood = {
                    id: undefined,
                    name: undefined,
                    marketName: undefined,
                    location: {
                        latitude: '',
                        longitude: '',
                    } as Location,
                };
            },
            initData() {
                this.neighborhood = this.obj as Neighborhood;
                this.longitude = this.neighborhood.location?.longitude ?? '';
                this.latitude = this.neighborhood.location?.latitude ?? '';
                this.isActive = this.obj.active ?? false;
                this.selectedOption = this.obj.marketName ?? 'Boston';
            },
            async toggle() {
                this.isActive = !this.isActive;
            },
            closeModal() {
                this.$emit('closed');
            },
        },
    });
