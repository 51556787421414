
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import EventBus from '@/eventbus';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { getNotificationStrings, notificationClickHandler } from '@/tsfiles/notificationutils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import Avatar from '@/components/Avatar.vue';
    import { PageMessage } from '@/tsfiles/interfaces';
    import { SharedConstants, NotificationService, Notification, GenericPageRetrieval, FilterOption } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserNotifications',

        components: {
            'url-avatar': Avatar,
        },

        props: {},

        data() {
            return {
                notifications: [] as Notification[],
                totalItemsNoPaging: 0,
                totalUnread: 0,
                unreadCheckbox: true,

                currentPage: 1,
                perPage: constants.NOTIFICATIONS_PER_PAGE,
            };
        },

        mounted() {
            this.loadNotifications();
            EventBus.$on(SharedConstants.PUBSUB_REFRESH_NOTIFICATIONS, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_LIKE_RECEIVED, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_DATE_MATCH, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_PENDING_DATE_MATCH, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_CHAT_POST, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_UNMATCHED, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_REMINDER, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_RESCHEDULED, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_DELETED_ACCOUNT, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_POST_DATE_SURVEY, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_AVAILABILITY_RECEIVED, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_PHONE_NUMBER_RECEIVED, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_PLAN_SUGGESTED, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_PLAN_APPROVED, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_SCORECARD, this.loadNotifications);
            EventBus.$on(SharedConstants.NOTIFICATION_WOC_RECOMMENDATION_CREATED, this.loadNotifications);
        },

        beforeDestroy() {
            EventBus.$off(SharedConstants.PUBSUB_REFRESH_NOTIFICATIONS, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_LIKE_RECEIVED, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_DATE_MATCH, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_PENDING_DATE_MATCH, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_CHAT_POST, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_UNMATCHED, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_REMINDER, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_RESCHEDULED, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_DELETED_ACCOUNT, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_POST_DATE_SURVEY, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_AVAILABILITY_RECEIVED, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_PHONE_NUMBER_RECEIVED, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_PLAN_SUGGESTED, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_PLAN_APPROVED, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_SCORECARD, this.loadNotifications);
            EventBus.$off(SharedConstants.NOTIFICATION_WOC_RECOMMENDATION_CREATED, this.loadNotifications);
        },

        computed: {},

        methods: {
            async loadNotifications() {
                //
                // If unreadCheckbox is true, we set the filter to unread_only items
                //
                const filterOptions = [] as FilterOption[];
                if (this.unreadCheckbox) {
                    filterOptions.push({ type: SharedConstants.FILTER_NOTIFICATIONS_UNREAD_ONLY });
                }

                try {
                    const ret = await ApiUtils.apiWrapper(NotificationService.getUserNotifications, {
                        pageNumber: this.currentPage,
                        numberOfItems: this.perPage,
                        filterBy: filterOptions,
                    } as GenericPageRetrieval);

                    if (ret.list) {
                        this.notifications = ret.list;
                        this.totalItemsNoPaging = ret.totalItemsIrregardlessOfPaging as number;
                        this.totalUnread = ret.totalUnreadItems as number;
                    } else {
                        this.notifications = [] as Notification[];
                        this.totalItemsNoPaging = 0;
                        this.totalUnread = 0;
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            getNotificationImageUrl(msg: Notification): string {
                if (msg.isMe) {
                    return this.$store.getters.getAvatarUrl;
                } else if (msg.creator && msg.creator.avatar) {
                    if (msg.creator.avatar.cachedImageUrl) {
                        return msg.creator.avatar.cachedImageUrl;
                    } else if (msg.creator.avatar.imageUrl) {
                        return msg.creator.avatar.imageUrl;
                    }
                }

                return '/favicon-32x32.png';
            },

            getNotificationString(msg: Notification): string {
                const shortAndLong = getNotificationStrings(msg);
                return shortAndLong.long;
            },

            pageChanged(newPage: number) {
                this.currentPage = newPage;
                this.loadNotifications();
            },

            //
            // When a user clicks on a notification message, we go to different places
            // depending on what the notification is. The MainApp gets the
            // closeSingleNotification and will handle deleting it.
            //
            notificationClick(msg: Notification) {
                notificationClickHandler(msg);
                this.markNotificationAsRead(msg);
            },

            async markNotificationAsRead(msg: Notification) {
                try {
                    await ApiUtils.apiWrapper(NotificationService.markAsReadNotification, msg.notificationId as number);
                    Vue.set(msg, 'markedAsRead', true);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
