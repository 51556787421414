
    import Vue, { PropOptions } from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { TimeUtils } from '@/tsfiles/timeutils';

    import {
        DatingService,
        GetWocRecommendationsResponse,
        PublicUser,
        SaveWocRecommendationRequest,
        SharedConstants,
        WocRecommendation,
    } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'WocRecommendationModal',

        components: {},

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<PublicUser>,
            pendingRecommendation: {
                type: Boolean,
                required: false,
                default: false,
            } as PropOptions<boolean>,
        },

        data() {
            return {
                prospectUserId: undefined,
                votes: undefined,
                recommendations: [] as WocRecommendation[] | undefined,
                saving: false,
                showSaveError: false,
                loading: true,
            };
        },

        computed: {
            subtitle(): string {
                return `Recommend someone to ` + (this.user?.firstName || 'user');
            },
        },
        mounted() {
            this.getRecommendations();
        },

        methods: {
            disableSave(): boolean {
                return this.saving || this.prospectUserId === 0 || this.votes === 0;
            },
            async saveRecommendation() {
                if (!this.user || !this.user.userId || !this.prospectUserId || !this.votes) {
                    logInvalidParams(this.$options.name, 'saveRecommendation');
                    return;
                }
                const confirmed = await this.$bvModal.msgBoxConfirm(
                    'Saving this recommendation will trigger notifications to both the subject user and the prospect user. Do you want to continue?',
                    {
                        title: 'Please Confirm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'YES',
                        cancelTitle: 'NO',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    },
                );
                if (!confirmed) {
                    return;
                }
                this.showSaveError = false;

                var post = {
                    subjectUserId: this.user.userId,
                    prospectUserId: this.prospectUserId,
                    votes: this.votes,
                } as SaveWocRecommendationRequest;

                try {
                    this.saving = true;
                    const ret = await ApiUtils.apiWrapper(DatingService.saveWocRecommendation, post);
                } catch (error: any) {
                    if (error?.response?.data?.error == SharedConstants.WARNING_RESOURCE_EXHAUSTED) {
                        this.showSaveError = true;
                        return;
                    }
                    Utils.CommonErrorHandler(error);
                } finally {
                    setTimeout(() => {
                        this.saving = false;
                    }, 500);
                    if (this.showSaveError == false) {
                        this.getRecommendations();
                    }
                }
            },
            async getRecommendations() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'getRecommendations');
                    return;
                }
                try {
                    this.loading = true;
                    const ret = await ApiUtils.apiWrapper(
                        this.pendingRecommendation ? DatingService.getWocRecommendations : DatingService.getAllWocRecommendations,
                        { subjectUserId: this.user.userId },
                    );

                    if (ret && ret.recommendations) {
                        this.recommendations = ret.recommendations;
                    }

                    const ret2 = await ApiUtils.apiWrapper(DatingService.getWocRecommendations, { subjectUserId: this.user.userId });
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                } finally {
                    setTimeout(() => {
                        this.loading = false;
                    }, 700);
                }
            },
            formatDateTime(utcDateStr: string): string {
                if (!utcDateStr) return '';
                return TimeUtils.ConvertUtcToEst(utcDateStr, false);
            },
            getRecommendationTableFields(): any[] {
                if (this.pendingRecommendation) {
                    return [
                        { key: 'prospectUserName', label: 'Prospect User' },
                        { key: 'votes', label: 'Votes' },
                        { key: 'created', label: 'Created' },
                    ];
                }
                return [
                    { key: 'prospectUserName', label: 'Prospect User' },
                    { key: 'votes', label: 'Votes' },
                    { key: 'created', label: 'Created' },
                    { key: 'userSwipe', label: 'User Swipe' },
                    { key: 'prospectSwipe', label: 'Prospect Swipe' },
                    { key: 'matchId', label: 'Match' },
                    { key: 'recommendingUserName', label: 'Recommended By' },
                ];
            },
        },
    });
