
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { PublicUser, DatingService, Match, SharedConstants } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserMatches',

        components: {},

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<PublicUser>,
        },

        data() {
            return {
                matches: {} as Match[],

                matchFields: [
                    { key: 'partnerAvatarUrl', tdClass: 'align-middle' },
                    { key: 'matchId', tdClass: 'align-middle' },
                    { key: 'date', tdClass: 'align-middle' },
                    { key: 'partnerUserId', tdClass: 'align-middle' },
                    { key: 'partnerName', tdClass: 'align-middle' },
                    { key: 'activeText', tdClass: 'align-middle' },
                    { key: 'unmatchReason', tdClass: 'align-middle' },
                    { key: 'chatUnreadCount', tdClass: 'align-middle' },
                ],
                allItems: [] as any[],
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: PublicUser, oldVal: PublicUser) {
                    if (newVal && newVal !== oldVal) {
                        this.fetchMatches();
                    }
                },
            },
        },

        computed: {},

        methods: {
            async fetchMatches() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'fetchMatches');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(DatingService.getPastMatches, {
                        userId: this.user.userId,
                        page: 1,
                        pageSize: SharedConstants.DATING_PAST_MATCHES_PAGE_SIZE,
                    });

                    this.$emit('matchesCount', ret.totalRecords);

                    if (ret && ret.matches && ret.matches.length > 0) {
                        Vue.set(this, 'matches', ret.matches);
                    } else {
                        Vue.set(this, 'matches', [] as Match[]);
                    }

                    this.buildItemList();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            buildItemList() {
                if (this.matches.length === 0) {
                    this.allItems = [] as any[];
                    return;
                }

                const newItems = [] as any[];
                for (const item of this.matches) {
                    //
                    // Push the items onto openItems, which is what will show up in the table (if
                    // there is a field for that item).
                    //
                    newItems.push({
                        partnerAvatarUrl: item.partnerAvatarUrl,
                        userId: item.userId,
                        matchId: item.matchId,
                        partnerUserId: item.partnerUserId,
                        partnerName: item.partnerName,
                        active: item.active,
                        unmatchReason: item.unmatchReason,
                        chatUnreadCount: item.chatUnreadCount,
                        date: item.date,
                        activeText: item.active ? 'Active' : 'Inactive',
                    });
                }

                this.allItems = newItems;
            },

            async userClicked(item: any) {
                if (item && item.matchId) {
                    const url = this.$router.resolve({
                        name: constants.ROUTE_USER_DATE,
                        query: { matchId: item.matchId.toString() },
                    }).href;
                    window.open(url, '_blank');
                }
            },
        },
    });
