
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import CreateUpdateModal from '@/components/admin/CreateUpdateNeighborhood.vue';
    import { Neighborhood, AdminService, SharedConstants } from '@bostonventurestudio/lola-api';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { th } from 'date-fns/locale';

    export default mixins(VueConstants).extend({
        name: 'MarketNeighborhood',
        components: {
            CreateUpdateModal,
        },
        data() {
            return {
                filters: {} as any,
                neighborhoods: [] as Neighborhood[],
                neighborhoodObj: {} as Neighborhood,
                pagination: {
                    page: 1,
                    pageSize: SharedConstants.MARKET_NEIGHBORHOOD_PAGE_SIZE,
                    totalRecords: 0,
                },
                currentPage: 1,
                showEditModal: false,
                isModalVisible: false,
                currentTab: 0,
                currentNavigation: { page: constants.ROUTE_MARKET_NEIGHBORHOOD } as PageNavigationData,
            };
        },
        mounted() {
            //
            // If page navigation contains data, process.
            //
            const nav = this.$store.getters.getPageNavigation(constants.ROUTE_MARKET_NEIGHBORHOOD);
            if (nav && nav.tab) {
                this.$nextTick(() => {
                    this.currentTab = nav.tab;
                    this.currentNavigation.tab = nav.tab;
                });
            }
            this.fetchNeighborhoods({ page: 1, pageSize: SharedConstants.MARKET_NEIGHBORHOOD_PAGE_SIZE });
        },
        computed: {
            hasAccess(): boolean {
                return this.$store.getters.isSignedIn && RoleUtils.CanSupportUsers();
            },
        },
        methods: {
            async fetchNeighborhoods(filters: any) {
                try {
                    let query = {};
                    query = {
                        page: filters.page || 1, // Use the page from filters, default to 1 if not provided
                        pageSize: filters.pageSize || SharedConstants.MARKET_NEIGHBORHOOD_PAGE_SIZE, // Use pageSize from filters or default constant
                    };
                    const ret = await ApiUtils.apiWrapper(AdminService.getNeighborhoods, query);
                    if (ret && ret.neighborhoods && ret.neighborhoods.length > 0) {
                        this.neighborhoods = ret.neighborhoods;
                        this.pagination = {
                            page: this.pagination.page,
                            pageSize: this.pagination.pageSize,
                            totalRecords: ret.totalCount ?? 0,
                        };
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            openModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            reload() {
                this.fetchNeighborhoods(this.filters);
            },
            async handlePageChange(page: number) {
                // Update the page number and perform the search again
                var filters = this.filters;
                filters.page = page;
                await this.fetchNeighborhoods(filters);
            },

            showPagination(): number {
                return this.neighborhoods && this.neighborhoods?.length;
            },
            /**
             * Returns a string representing the pagination information.
             * @returns {string} The pagination information.
             */
            getPaginationInfo(): string {
                var showing = (this.pagination.page - 1) * this.pagination.pageSize + 1;
                return (
                    'Showing ' +
                    showing +
                    ' - ' +
                    Math.min(this.pagination.page * this.pagination.pageSize, this.pagination.totalRecords) +
                    ' of ' +
                    this.pagination.totalRecords +
                    ' Neighborhoods.'
                );
            },
        },
    });
