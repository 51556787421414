
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';
    import { PubSubEvent } from '@/tsfiles/pubsub';
    import { Utils } from '@/tsfiles/utils';
    import { TimeUtils } from '@/tsfiles/timeutils';
    import * as analytics from '@/tsfiles/analytics';
    import { DateTime as LuxonDateTime } from 'luxon';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import EventBus from '@/eventbus';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import ChatUi from '@/components/chat/Chat.vue';
    import OffensiveReportModal from '@/generic-modals/OffensiveReportModal.vue';
    import {
        SharedConstants,
        Match,
        ChatService,
        Chat,
        Post,
        PublicUser,
        AddPostRequest,
        GenericPageRetrieval,
        AddCvRequest,
        DatingService,
        ApproveDatePlanRequest,
        DatePlan,
    } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserChat',

        components: {
            'chat-ui': ChatUi,
            'offensive-report-modal': OffensiveReportModal,
        },

        props: {
            match: {
                type: Object,
                required: true,
            } as PropOptions<Match>,

            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                chat: {} as Chat,
                posts: [] as Post[],

                message: '',
                prevMessage: '', // For determining if user is typing
                readTimeoutId: undefined as any | undefined,
                typingTimeoutId: undefined as any | undefined,
                isTyping: false,
                showOtherMemberTyping: false,

                submitting: false,
                fetchingChats: false,
                canSharePhone: true,

                totalItems: 0,
                currentPage: 1,
                perPage: constants.CHAT_POSTS_PER_PAGE,
                showFlagChatModal: false,
            };
        },

        watch: {
            match: {
                immediate: true,
                deep: true,
                handler(newVal: Match, oldVal: Match) {
                    if (newVal && newVal != oldVal) {
                        this.fetchChat(newVal.matchId as number);
                    } else {
                        clearTimeout(this.readTimeoutId);
                        clearTimeout(this.typingTimeoutId);

                        this.readTimeoutId = undefined as any | undefined;
                        this.typingTimeoutId = undefined as any | undefined;
                    }
                },
            },
        },

        mounted() {
            //
            // The new-chat-post event comes in through pubSub.  That message is special
            // and includes all the data that Post has in the post array.  We could just
            // insert it at the front, but because of paging, and the fact that the webUI
            // won't be used by real users most likely, we just fetch the first page over again.
            //
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_UNMATCHED, this.dateUnmatched);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_RESCHEDULED, this.dateRescheduled);
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_CHAT_POST, this.newPostNotification);
            EventBus.$on(SharedConstants.NOTIFICATION_PHONE_NUMBER_RECEIVED, this.newPostNotification);
            EventBus.$on(SharedConstants.NOTIFICATION_AVAILABILITY_RECEIVED, this.newPostNotification);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_PLAN_SUGGESTED, this.newPostNotification);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_PLAN_APPROVED, this.newPostNotification);
            EventBus.$on(SharedConstants.PUBSUB_CHAT_USER_IS_TYPING, this.userTypingNotification);
            EventBus.$on(SharedConstants.PUBSUB_CHAT_FLAGGED, this.chatFlaggedNotification);
            EventBus.$on(SharedConstants.PUBSUB_CHAT_POST_DELIVERED, this.chatPostDeliveredNotification);
            EventBus.$on(SharedConstants.PUBSUB_CHAT_POST_READ, this.chatPostReadNotification);
        },

        beforeDestroy() {
            clearTimeout(this.readTimeoutId);
            clearTimeout(this.typingTimeoutId);

            this.readTimeoutId = undefined as any | undefined;
            this.typingTimeoutId = undefined as any | undefined;

            EventBus.$off(SharedConstants.NOTIFICATION_DATE_UNMATCHED, this.dateUnmatched);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_RESCHEDULED, this.dateRescheduled);
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_CHAT_POST, this.newPostNotification);
            EventBus.$off(SharedConstants.NOTIFICATION_PHONE_NUMBER_RECEIVED, this.newPostNotification);
            EventBus.$off(SharedConstants.PUBSUB_CHAT_USER_IS_TYPING, this.userTypingNotification);
            EventBus.$off(SharedConstants.PUBSUB_CHAT_FLAGGED, this.chatFlaggedNotification);
            EventBus.$off(SharedConstants.PUBSUB_CHAT_POST_DELIVERED, this.chatPostDeliveredNotification);
            EventBus.$off(SharedConstants.PUBSUB_CHAT_POST_READ, this.chatPostReadNotification);
        },

        computed: {
            isPendingMatch(): boolean {
                return this.match?.date === undefined;
            },
            dateInPast(): boolean {
                const lDate = LuxonDateTime.fromISO(this.match.date as string);
                return lDate < LuxonDateTime.local();
            },
        },

        methods: {
            showDateHeader(idx: number): boolean {
                if (!this.chat || !this.posts || idx < 0 || idx >= this.posts.length) {
                    return false;
                }

                const curDate = LuxonDateTime.fromISO(this.posts[idx].updated).toRelativeCalendar();
                if (curDate === 'today') {
                    return false;
                } else if (idx === 0) {
                    return true;
                } else {
                    const prevDate = LuxonDateTime.fromISO(this.posts[idx - 1].updated).toRelativeCalendar();
                    return curDate !== prevDate;
                }

                return false;
            },

            currentDateHeader(item: Post): string {
                if (!item) {
                    return '';
                }

                const curDate = LuxonDateTime.fromISO(item.updated).toRelativeCalendar();
                if (curDate === 'today') {
                    return '';
                } else if (curDate === 'yesterday') {
                    return 'Yesterday';
                } else {
                    return LuxonDateTime.fromISO(item.updated).toLocaleString(LuxonDateTime.DATE_FULL);
                }
            },

            getPostAuthor(item: Post): PublicUser {
                if (!this.chat || !this.chat.initiator || !this.chat.participant) {
                    logInvalidParams(this.$options.name, 'getPostAuthor');
                    return {} as PublicUser;
                }

                if (this.chat.initiator.userId === item.authorUserId) {
                    return this.chat.initiator;
                }

                return this.chat.participant;
            },

            async acceptDatePlan(item: Post) {
                if (!this.chat || !item.message) {
                    logInvalidParams(this.$options.name, 'acceptDatePlan');
                }

                try {
                    var json = JSON.parse(item.message as string);
                    var plan = json.datePlan as DatePlan;

                    await ApiUtils.apiWrapper(DatingService.approveDatePlan, {
                        matchId: this.match.matchId,
                        planId: plan.planId,
                    } as ApproveDatePlanRequest);

                    this.$bvModal
                        .msgBoxOk('You have accepted the date plan!', {
                            title: 'Date Plan Accepted',
                            size: 'md',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true,
                        })
                        .then((value) => {})
                        .catch((err) => {
                            // An error occurred
                        });

                    this.fetchChat(this.match.matchId as number);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async editDatePlan(item: Post) {
                if (!this.chat || !item.message) {
                    logInvalidParams(this.$options.name, 'editDatePlan');
                }

                alert('Edit date plan not implemented yet!');
                return;

                // try {
                //     var json = JSON.parse(item.message as string);
                //     var plan = json.datePlan as DatePlan;

                //     this.$bvModal
                //         .msgBoxConfirm('Not implemented yet!', {
                //             title: 'Edit Date Plan ',
                //             size: 'md',
                //             buttonSize: 'md',
                //             okVariant: 'danger',
                //             okTitle: 'Update',
                //             cancelTitle: 'Cancel',
                //             footerClass: 'p-2',
                //             hideHeaderClose: false,
                //             centered: true,
                //         })
                //         .then((value) => {})
                //         .catch((err) => {
                //             // An error occurred
                //         });
                // } catch (error) {
                //     Utils.CommonErrorHandler(error);
                // }
            },

            async fetchChat(matchId: number) {
                try {
                    this.chat = await ApiUtils.apiWrapper(ChatService.getChat, {
                        matchId,
                    });
                    if (this.chat && this.chat.chatId) {
                        this.$emit('update-last-active', this.chat?.participantLastActive);
                        this.fetchChatPosts();
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchChatPosts() {
                if (!this.chat || !this.chat.chatId) {
                    logInvalidParams(this.$options.name, 'fetchChatPosts');
                    return;
                }

                try {
                    const pagedResults = await ChatService.getChatPosts({
                        uniqueIdRequested: this.match.matchId,
                        pageNumber: this.currentPage,
                        numberOfItems: this.perPage,
                    } as GenericPageRetrieval);

                    if (pagedResults && pagedResults.posts) {
                        Vue.set(this, 'posts', pagedResults.posts);
                        this.totalItems = pagedResults.totalItemsIrregardlessOfPaging as number;

                        // See if any of the posts are CV from this authed user.  If so, they
                        // cannot share the phone again.  This hacky...
                        if (this.readonly) {
                            for (const post of this.posts) {
                                const json = JSON.parse(post.message as string);
                                if (json.cv && this.$store.getters.isAuthedUserId(post.authorUserId)) {
                                    this.canSharePhone = false;
                                    break;
                                }
                            }
                        }

                        //
                        // Why the timeout?  That's a hack to allow the originator of the post
                        // to refetch the page again.  If posts were inserted into the array on the
                        // fly, this would not be needed.
                        //
                        this.readTimeoutId = setTimeout(async () => {
                            this.markPostsAsRead();
                        }, 2000);
                    } else {
                        Vue.set(this, 'posts', [] as Post[]);
                        this.totalItems = 0;
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async handleSubmit() {
                if (this.fetchingChats || this.submitting || this.message === '') {
                    return;
                }

                this.submitting = true;

                const addPostRequest: AddPostRequest = {
                    matchId: this.match.matchId,
                    message: JSON.stringify({ message: this.message }),
                };

                try {
                    //
                    // I wanted to play the sound after the server added the Post, but
                    // safari refuses to play the sound sometimes, with their
                    // permission denied message, thinking it's programmatic playing not
                    // related to a user interaction.  Putting just before await, which
                    // seems to work
                    // If more audio work is done, pull this into something common...
                    //
                    // 2023: None of the browsers will play this, since they think there's
                    // no user interaction.  There is, but we'll need another solution...
                    //
                    let audio = this.$store.getters.getPostBeep;
                    if (!audio) {
                        audio = new Audio('beep.mp3');
                        this.$store.commit('setPostBeep', audio);
                    }
                    if (audio) {
                        // audio.play();
                    }

                    //
                    // For now, we refetch instead of inserting the returned post.
                    //
                    await ApiUtils.apiWrapper(ChatService.addPostToChat, addPostRequest);

                    this.currentPage = 1; // Go back to page 1 if the post worked
                    this.fetchChatPosts();
                    this.message = '';
                    this.prevMessage = '';
                    this.submitting = false;
                } catch (error) {
                    this.submitting = false;
                    Utils.CommonErrorHandler(error);
                }
            },

            pageChanged(newPage: number) {
                return;

                // this.$router.replace({
                // name: constants.ROUTE_USER_CHATS,
                // params: { userId: this.$store.getters.getAuthedUserId },
                // query: { id: this.queryId.toString(), pg: newPage.toString() },
                // });
            },

            //
            // Handle changes to the input, so we can show 'typing' to the other user.
            // For webUI testing, put in some simple logic.  For example, if the
            // user types in N characters, send the message to the server.  Once the
            // timeout happens, tell the server the user stopped typing.
            //
            async inputChanging() {
                if (!this.chat.chatId || this.chat.state !== SharedConstants.CHAT_STATE_ACTIVE) {
                    return;
                }

                // Send a 'typing' message if the user entered enough characters.  This
                // message is sent every time enough keystrokes are hit.
                if (Math.abs(this.message.length - this.prevMessage.length) < SharedConstants.CHAT_MIN_CHARS_FOR_TYPING_MESSAGE) {
                    return;
                }

                this.prevMessage = this.message;

                try {
                    await ApiUtils.apiWrapper(ChatService.userTyping, {
                        matchId: this.match.matchId,
                    });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // If not on the current chat or not on the first page of the current chat, mark message as delivered.
            // fetchChatPosts and pageChanged will also need to potentially mark as read.
            //
            async newPostNotification(data: any) {
                if (this.typingTimeoutId) {
                    clearTimeout(this.typingTimeoutId);
                    this.typingTimeoutId = undefined as any | undefined;
                    this.isTyping = false;
                    this.prevMessage = '';
                    this.showOtherMemberTyping = false;
                }

                const post = JSON.parse(data.jsonData) as Post;
                if (this.chat.chatId === post.chatId && this.currentPage === 1) {
                    this.fetchChatPosts();
                } else {
                    //
                    // Why the timeout?  That's a hack to allow the originator of the post
                    // to refetch the page again.  If posts were inserted into the array on the
                    // fly, this would not be needed.
                    //
                    setTimeout(async () => {
                        try {
                            await ApiUtils.apiWrapper(ChatService.chatPostDelivered, post);
                        } catch (error) {
                            Utils.CommonErrorHandler(error);
                        }
                    }, 1000);
                }
            },

            async markPostsAsRead() {
                if (!this.match || !this.chat.chatId || this.chat.state !== SharedConstants.CHAT_STATE_ACTIVE) {
                    return;
                }

                try {
                    for (const post of this.posts) {
                        if (post.authorUserId !== this.$store.getters.getAuthedUserId && post.state !== SharedConstants.CHAT_POST_STATE_READ) {
                            await ApiUtils.apiWrapper(ChatService.chatPostRead, post);
                        }
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            userStoppedTyping() {
                if (this.typingTimeoutId) {
                    clearTimeout(this.typingTimeoutId);
                    this.showOtherMemberTyping = false;
                    this.typingTimeoutId = undefined as any | undefined;
                    this.prevMessage = '';
                }
            },

            userTypingNotification(data: PubSubEvent) {
                const chat = JSON.parse(data.jsonData) as Chat;
                if (this.chat.chatId !== chat.chatId || this.chat.state !== SharedConstants.CHAT_STATE_ACTIVE) {
                    return;
                }

                this.showOtherMemberTyping = true;

                if (this.typingTimeoutId) {
                    clearTimeout(this.typingTimeoutId);
                    this.typingTimeoutId = undefined as any | undefined;
                }

                // Reset timer to know when to stop typing
                this.typingTimeoutId = setTimeout(() => {
                    this.userStoppedTyping();
                }, SharedConstants.CHAT_CLEAR_TYPING_MESSAGE_TIMEOUT_MS);
            },

            chatFlaggedNotification(data: PubSubEvent) {
                // this.fetchAllChats();
            },

            // A chat post 'delivered' message came in.  Do not error if no currentChat
            // or if there's no match.  These are asynchronous and could happen at any time, not related
            // to our current chat.
            chatPostDeliveredNotification(data: PubSubEvent) {
                const post = JSON.parse(data.jsonData) as Post;
                if (this.chat.chatId !== post.chatId) {
                    return;
                }

                for (let i = 0; i < this.posts.length; i++) {
                    if (this.posts[i].postId === post.postId) {
                        this.posts[i].state = SharedConstants.CHAT_POST_STATE_DELIVERED;
                        break;
                    }
                }
            },

            // A chat post 'read' message came in.  Do not error if no currentChat
            // or if there's no match.  These are asynchronous and could happen at any time, not related
            // to our current chat.
            chatPostReadNotification(data: PubSubEvent) {
                const post = JSON.parse(data.jsonData) as Post;
                if (this.chat.chatId !== post.chatId) {
                    return;
                }

                for (let i = 0; i < this.posts.length; i++) {
                    if (this.posts[i].postId === post.postId) {
                        this.posts[i].state = SharedConstants.CHAT_POST_STATE_READ;
                        break;
                    }
                }
            },

            async flagChat(message: string) {
                if (!this.chat.chatId || this.chat.state !== SharedConstants.CHAT_STATE_ACTIVE) {
                    logInvalidParams(this.$options.name, 'flagChat');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(ChatService.flagChat, {
                        matchId: this.match.matchId,
                        reason: message,
                    });

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_FLAG_CHAT, this.chat.chatId.toString());
                    alert('This chat has been reported as potentially abusing the platform');

                    this.fetchChat(this.match.matchId as number);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async sharePhone() {
                if (!this.chat.chatId || !this.dateInPast) {
                    logInvalidParams(this.$options.name, 'sharePhone');
                    return;
                }

                const addCvRequest: AddCvRequest = {
                    matchId: this.match.matchId,
                };

                try {
                    //
                    // For now, we refetch instead of inserting the returned post.
                    //
                    await ApiUtils.apiWrapper(ChatService.addCvToChat, addCvRequest);
                    this.fetchChatPosts();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            dateUnmatched() {
                alert("This date has been unmatched.  You can't chat anymore.");
                this.$router.replace({ name: constants.ROUTE_USER_CALENDAR });
            },

            dateRescheduled() {
                alert("This date has been rescheduled.  You can't chat anymore.");
                this.$router.replace({ name: constants.ROUTE_USER_CALENDAR });
            },
        },
    });
