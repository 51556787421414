
    import Vue from 'vue';
    import { SwipeRequest } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'DatingSwipeModal',

        props: {},

        data() {
            return {
                note: '',
                swipeType: 'like',
                isRecommended: false,
                swipeOptions: [
                    { value: 'like', text: 'Like', selected: true },
                    { value: 'dislike', text: 'Dislike' },
                ],
                allowEmpty: true,
                minLength: 1,
                maxLength: 1000,
            };
        },

        computed: {
            invalidInput(): boolean {
                return !this.allowEmpty && this.note.trim().length < this.minLength && this.swipeType !== '';
            },
        },

        methods: {
            submit() {
                if (this.invalidInput) {
                    return;
                }
                const swipeRequest = {
                    affinity: this.swipeType === 'like',
                    note: this.note || '',
                    isRecommended: this.isRecommended ? true : false,
                } as SwipeRequest;
                this.$emit('submit', swipeRequest);
            },
        },
    });
