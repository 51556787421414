var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.hasAccess)?_c('div',[_c('b-card',{staticClass:"gc-card w-100 p-2",attrs:{"no-body":"","text-variant":"white"}},[_c('span',{staticClass:"card-header-text p-3 pb-0"},[_vm._v("Market Neighborhoods")]),_c('b-tabs',{staticClass:"m-2 mt-3",attrs:{"justified":"","nav-class":"text-nowrap","content-class":"m-0 p-0 mt-2"},on:{"activate-tab":function($event){_vm.currentNavigation.tab = $event}},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('b-tab',{attrs:{"title":"Market Neighborhoods"}},[_c('div',[_c('h2',[_vm._v("Neighborhoods")]),_c('b-button',{staticClass:"btn btn-primary mb-3",on:{"click":function($event){_vm.openModal();
                            _vm.showEditModal = false;}}},[_vm._v(" Create Neighborhood ")]),(_vm.showPagination())?_c('div',{staticClass:"pagination-info ml-3"},[_vm._v(" "+_vm._s(_vm.getPaginationInfo())+" ")]):_vm._e(),_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Market")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Location")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Active")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.neighborhoods),function(neighborhood){return _c('tr',{key:neighborhood.id,staticClass:"text-center"},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(neighborhood.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(neighborhood.marketName))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(neighborhood.location))]),_c('td',{staticClass:"text-center",class:{
                                        'bg-success text-dark font-weight-bold': neighborhood.active,
                                        'bg-secondary text-dark': !neighborhood.active,
                                    }},[_vm._v(" "+_vm._s(neighborhood.active ? 'Active' : 'Inactive')+" ")]),_c('td',[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){_vm.openModal();
                                            _vm.neighborhoodObj = neighborhood;
                                            _vm.showEditModal = true;}}},[_vm._v(" Edit ")])])])}),0)]),(_vm.showPagination())?_c('div',{staticClass:"d-flex justify-content-end pagination-box"},[(_vm.showPagination())?_c('b-pagination',{staticClass:"m-0 p-0",attrs:{"total-rows":_vm.pagination.totalRecords,"per-page":_vm.pagination.pageSize},on:{"change":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1):_vm._e()],1),(_vm.isModalVisible)?_c('CreateUpdateModal',{attrs:{"obj":_vm.neighborhoodObj,"isCreateMode":!_vm.showEditModal},on:{"closed":_vm.closeModal,"saved":_vm.reload}}):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }